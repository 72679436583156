import { Label } from "@acdc2/ui/components/label";
import { Input } from "@acdc2/ui/components/input";
import { useTranslation } from "react-i18next";
import { useId } from "react";

type Props = React.ComponentProps<typeof Input>;

export default function TableCellColumnInput(props: Props): JSX.Element {
  const { t } = useTranslation();
  const columnInputId = useId();

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor={columnInputId}>{t("TableCellColumnInput.label")}</Label>
      <Input
        id={columnInputId}
        placeholder={t("TableCellColumnInput.placeholder")}
        type="number"
        inputMode="numeric"
        min={1}
        required
        {...props}
      />
    </div>
  );
}
