import { Dispatch } from "react";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { useTranslation } from "react-i18next";
import { TableHeaderCellCreationTabFragment } from "../../client/generated";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  fragment: TableHeaderCellCreationTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function TableHeaderCellCreationTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.TableHeaderCellCreation, fragment.id)}
    >
      <NavbarTabHeader
        title={t("TableHeaderCellCreationTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      Table Header Cell Creation Tab
    </NavbarTabContent>
  );
}
