import { Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditorAction, TabPrefix, makeIdentifier } from "../editor/reducers";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@acdc2/ui/components/popover";
import { Button } from "@acdc2/ui/components/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@acdc2/ui/components/command";
import { Icon } from "@mdi/react";
import { mdiTagPlusOutline } from "@mdi/js";
import { AttributeCreationButtonFragment } from "../client/generated";

type Props = {
  fragment: AttributeCreationButtonFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function AttributeCreationButton({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onSelect = async (tabIdentifier: string) => {
    setOpen(false);
    dispatch({
      type: "switchTab",
      tabIdentifier,
    });
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="flex-1 gap-1"
        >
          <Icon path={mdiTagPlusOutline} size="1rem" />
          <span>{t("AttributeCreationButton.label")}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command>
          <CommandInput
            placeholder={t("AttributeCreationButton.placeholder")}
          />
          <CommandList>
            <CommandEmpty>{t("AttributeCreationButton.empty")}</CommandEmpty>

            <CommandGroup
              heading={t("AttributeCreationButton.groups.text.title")}
            >
              <CommandItem
                value={makeIdentifier(
                  TabPrefix.HeadingLevelCreation,
                  fragment.id,
                )}
                onSelect={onSelect}
              >
                {t("AttributeCreationButton.groups.text.labels.headingLevel")}
              </CommandItem>
              <CommandItem
                value={makeIdentifier(TabPrefix.TextCreation, fragment.id)}
                onSelect={onSelect}
              >
                {t("AttributeCreationButton.groups.text.labels.text")}
              </CommandItem>
              <CommandItem
                value={makeIdentifier(
                  TabPrefix.ExpressionCreation,
                  fragment.id,
                )}
                onSelect={onSelect}
              >
                {t("AttributeCreationButton.groups.text.labels.expression")}
              </CommandItem>
            </CommandGroup>

            <CommandGroup
              heading={t("AttributeCreationButton.groups.lists.title")}
            >
              <CommandItem
                value={makeIdentifier(TabPrefix.ListCreation, fragment.id)}
                onSelect={onSelect}
              >
                {t("AttributeCreationButton.groups.lists.labels.list")}
              </CommandItem>
              <CommandItem
                value={makeIdentifier(TabPrefix.ListItemCreation, fragment.id)}
                onSelect={onSelect}
              >
                {t("AttributeCreationButton.groups.lists.labels.listItem")}
              </CommandItem>
              <CommandItem
                value={makeIdentifier(
                  TabPrefix.ListItemLabelCreation,
                  fragment.id,
                )}
                onSelect={onSelect}
              >
                {t("AttributeCreationButton.groups.lists.labels.listItemLabel")}
              </CommandItem>
            </CommandGroup>

            <CommandGroup
              heading={t("AttributeCreationButton.groups.media.title")}
            >
              <CommandItem
                value={makeIdentifier(TabPrefix.ImageCreation, fragment.id)}
                onSelect={onSelect}
              >
                {t("AttributeCreationButton.groups.media.labels.image")}
              </CommandItem>
              <CommandItem
                value={makeIdentifier(TabPrefix.FigureCreation, fragment.id)}
                onSelect={onSelect}
              >
                {t("AttributeCreationButton.groups.media.labels.figure")}
              </CommandItem>
              <CommandItem
                value={makeIdentifier(TabPrefix.CaptionCreation, fragment.id)}
                onSelect={onSelect}
              >
                {t("AttributeCreationButton.groups.media.labels.caption")}
              </CommandItem>
              <CommandItem
                value={makeIdentifier(
                  TabPrefix.AlternativeTextCreation,
                  fragment.id,
                )}
                onSelect={onSelect}
              >
                {t(
                  "AttributeCreationButton.groups.media.labels.alternativeText",
                )}
              </CommandItem>
            </CommandGroup>

            <CommandGroup
              heading={t("AttributeCreationButton.groups.tables.title")}
            >
              <CommandItem
                value={makeIdentifier(TabPrefix.TableCreation, fragment.id)}
                onSelect={onSelect}
              >
                {t("AttributeCreationButton.groups.tables.labels.table")}
              </CommandItem>
              <CommandItem
                value={makeIdentifier(TabPrefix.TableCellCreation, fragment.id)}
                onSelect={onSelect}
              >
                {t("AttributeCreationButton.groups.tables.labels.tableCell")}
              </CommandItem>
              <CommandItem
                value={makeIdentifier(
                  TabPrefix.TableHeaderCellCreation,
                  fragment.id,
                )}
                onSelect={onSelect}
              >
                {t(
                  "AttributeCreationButton.groups.tables.labels.tableHeaderCell",
                )}
              </CommandItem>
            </CommandGroup>

            <CommandGroup
              heading={t("AttributeCreationButton.groups.decorative.title")}
            >
              <CommandItem
                value={makeIdentifier(
                  TabPrefix.ExcludeReadingOrderCreation,
                  fragment.id,
                )}
                onSelect={onSelect}
              >
                {t(
                  "AttributeCreationButton.groups.decorative.labels.excludeReadingOrder",
                )}
              </CommandItem>
              <CommandItem
                value={makeIdentifier(
                  TabPrefix.PaginationArtifactCreation,
                  fragment.id,
                )}
                onSelect={onSelect}
              >
                {t(
                  "AttributeCreationButton.groups.decorative.labels.paginationArtifact",
                )}
              </CommandItem>
            </CommandGroup>

            <CommandGroup
              heading={t("AttributeCreationButton.groups.toc.title")}
            >
              <CommandItem
                value={makeIdentifier(
                  TabPrefix.TableOfContentsCreation,
                  fragment.id,
                )}
                onSelect={onSelect}
              >
                {t("AttributeCreationButton.groups.toc.labels.tableOfContents")}
              </CommandItem>
              <CommandItem
                value={makeIdentifier(
                  TabPrefix.TableOfContentsHeadingCreation,
                  fragment.id,
                )}
                onSelect={onSelect}
              >
                {t(
                  "AttributeCreationButton.groups.toc.labels.tableOfContentsHeading",
                )}
              </CommandItem>
              <CommandItem
                value={makeIdentifier(
                  TabPrefix.TableOfContentsItemCreation,
                  fragment.id,
                )}
                onSelect={onSelect}
              >
                {t(
                  "AttributeCreationButton.groups.toc.labels.tableOfContentsItem",
                )}
              </CommandItem>
              <CommandItem
                value={makeIdentifier(
                  TabPrefix.TableOfContentsPageNumberCreation,
                  fragment.id,
                )}
                onSelect={onSelect}
              >
                {t(
                  "AttributeCreationButton.groups.toc.labels.tableOfContentsPageNumber",
                )}
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
