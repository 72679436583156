import { MetadataTitleLabelFragment } from "../client/generated";

type Props = {
  fragment: MetadataTitleLabelFragment;
};

export default function MetadataTitleLabel({ fragment }: Props): JSX.Element {
  return (
    <div className="flex flex-row gap-2 items-center">
      <div className="flex border-spacing-2 rounded-lg border-2 px-2">
        {fragment.language}
      </div>
      <div>{fragment.title}</div>
    </div>
  );
}
