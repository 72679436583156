import {
  AccessContextMenuItem,
  AccessContextMenuShortcut,
} from "@acdc2/ui/components/access-context-menu";
import { useTranslation } from "react-i18next";

type Props = {
  onDelete: () => void;
  disabled?: boolean;
};

export default function RegionDeleteContextMenuItem({
  onDelete,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <AccessContextMenuItem onClick={onDelete} disabled={disabled}>
      {t("RegionDeleteContextMenuItem.label")}
      <AccessContextMenuShortcut>
        {t("RegionDeleteContextMenuItem.shortcut")}
      </AccessContextMenuShortcut>
    </AccessContextMenuItem>
  );
}
