import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@acdc2/ui/components/select";
import React from "react";
import { useTranslation } from "react-i18next";
import { PaginationArtifactType } from "../../client/generated";

type Props = React.ComponentProps<typeof Select>;

export default function PaginationArtifactTypeSelect(
  props: Props,
): JSX.Element {
  const { t } = useTranslation();

  return (
    <Select {...props}>
      <SelectTrigger>
        <SelectValue
          placeholder={t("PaginationArtifactTypeSelect.placeholder")}
        />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={PaginationArtifactType.Header}>
          {t("PaginationArtifactTypeSelect.labels.header")}
        </SelectItem>
        <SelectItem value={PaginationArtifactType.Footer}>
          {t("PaginationArtifactTypeSelect.labels.footer")}
        </SelectItem>
        <SelectItem value={PaginationArtifactType.Watermark}>
          {t("PaginationArtifactTypeSelect.labels.watermark")}
        </SelectItem>
        <SelectItem value={PaginationArtifactType.PageNumber}>
          {t("PaginationArtifactTypeSelect.labels.numbers.page")}
        </SelectItem>
        <SelectItem value={PaginationArtifactType.BatesNumber}>
          {t("PaginationArtifactTypeSelect.labels.numbers.bates")}
        </SelectItem>
        <SelectItem value={PaginationArtifactType.LineNumber}>
          {t("PaginationArtifactTypeSelect.labels.numbers.line")}
        </SelectItem>
        <SelectItem value={PaginationArtifactType.RedactionIndicator}>
          {t("PaginationArtifactTypeSelect.labels.redactionIndicator")}
        </SelectItem>
      </SelectContent>
    </Select>
  );
}
