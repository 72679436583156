import { Icon } from "@mdi/react";

type Props = {
  path: string;
  description: string;
};

export function ButtonIconLabel({ path, description }: Props): JSX.Element {
  return (
    <div className="flex gap-4">
      <Icon path={path} size={1} />
      <span className="flex justify-center items-center">{description}</span>
    </div>
  );
}
