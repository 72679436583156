import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Dispatch } from "react";
import { PageEditorFragment } from "../client/generated";
import { EditorAction, EditorState } from "./reducers";
import Page from "./Page";
import PageMinimap from "./PageMinimap";
import RegionsToolOverlay from "../regions/RegionsToolOverlay";

type Props = {
  fragment: PageEditorFragment;
  state: EditorState;
  dispatch: Dispatch<EditorAction>;
};

export default function PageEditor({
  fragment,
  state,
  dispatch,
}: Props): JSX.Element {
  return (
    <TransformWrapper
      centerOnInit
      minScale={0.25}
      maxScale={4}
      doubleClick={{ step: 1 }}
      panning={{
        allowLeftClickPan: false,
        allowRightClickPan: false,
        allowMiddleClickPan: true,
        velocityDisabled: true,
      }}
    >
      <PageMinimap key={fragment.id} fragment={fragment} />
      <TransformComponent wrapperClass="!w-full !h-full bg-gray-100 dark:bg-gray-900">
        <Page fragment={fragment} />
        <RegionsToolOverlay
          key={fragment.id}
          state={state}
          fragment={fragment}
          dispatch={dispatch}
        />
      </TransformComponent>
    </TransformWrapper>
  );
}
