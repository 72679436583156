import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { TablePreviewFragment } from "../../client/generated";
import { Dispatch } from "react";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";

type Props = {
  fragment: TablePreviewFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function TablePreview({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      tabIdentifier={makeIdentifier(TabPrefix.TableUpdate, fragment.id)}
      label={t("TablePreview.label")}
      preview={`${fragment.rows}x${fragment.columns}`}
      dispatch={dispatch}
    />
  );
}
