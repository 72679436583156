import { useTranslation } from "react-i18next";
import { Accordion } from "@acdc2/ui/components/accordion";
import { EditorMetadataNavbarTabContentFragment } from "../client/generated";
import { TabIdentifier } from "./reducers";
import NavbarTabContent from "../shell/NavbarTabContent";
import MetadataAuthorAccordionItem from "../metadata/MetadataAuthorAccordionItem";
import MetadataKeywordAccordionItem from "../metadata/MetadataKeywordAccordionItem";
import MetadataDateAccordionItem from "../metadata/MetadataDateAccordionItem";
import MetadataTitleAccordionItem from "../metadata/MetadataTitleAccordionItem";
import NavbarTabHeader from "../shell/NavbarTabHeader";

type Props = {
  fragment: EditorMetadataNavbarTabContentFragment;
};

export default function EditorMetadataNavbarTabContent({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent value={TabIdentifier.Metadata}>
      <NavbarTabHeader title={t("EditorMetadataNavbarTabContent.title")} />
      <Accordion
        type="multiple"
        defaultValue={["titles", "authors", "keywords", "date"]}
        className="w-full"
      >
        <MetadataTitleAccordionItem fragment={fragment} />
        <MetadataAuthorAccordionItem fragment={fragment} />
        <MetadataKeywordAccordionItem fragment={fragment} />
        <MetadataDateAccordionItem fragment={fragment} />
      </Accordion>
    </NavbarTabContent>
  );
}
