import { useEffect, useState } from "react";
import { cn } from "@acdc2/ui/lib/utils";

type Props = {
  cx: number;
  cy: number;
  direction: "horizontal" | "vertical" | "primary" | "secondary";
  onDrag: (movementX: number, movementY: number) => void;
  onDone: () => void;
};

export default function RegionTransformHandle({
  cx,
  cy,
  direction,
  onDrag,
  onDone,
}: Props): JSX.Element {
  const [isDragged, setIsDragged] = useState(false);

  const onMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDragged(true);
  };

  useEffect(() => {
    if (!isDragged) return;

    const onMouseMove = (event: MouseEvent) => {
      if (event.buttons !== 1) {
        setIsDragged(false);
        return;
      }

      onDrag(event.movementX, event.movementY);
    };

    const onMouseUp = () => {
      setIsDragged(false);
      onDone();
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [isDragged, onDrag, onDone]);

  return (
    <>
      <rect
        x={cx - 8}
        y={cy - 8}
        width={16}
        height={16}
        fill="hsl(var(--brand))"
        stroke="transparent"
        strokeWidth={8}
        onMouseDown={onMouseDown}
        className={cn({
          "cursor-ns-resize": direction === "vertical",
          "cursor-ew-resize": direction === "horizontal",
          "cursor-nesw-resize": direction === "primary",
          "cursor-nwse-resize": direction === "secondary",
        })}
      />
      <rect
        x={cx - 4}
        y={cy - 4}
        width={8}
        height={8}
        fill="white"
        stroke="none"
        className="pointer-events-none"
      />
    </>
  );
}
