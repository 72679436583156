import { Button } from "@acdc2/ui/components/button";
import { Input } from "@acdc2/ui/components/input";
import { Column } from "@tanstack/react-table";
import { Icon } from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { DocumentRowFragment } from "../client/generated";

type Props = {
  column: Column<DocumentRowFragment>;
  placeholder: string;
};

export default function DocumentTableFilterInput({
  column,
  placeholder,
}: Props): JSX.Element {
  return (
    <div className="flex relative">
      <Input
        placeholder={placeholder}
        value={(column.getFilterValue() as string) ?? ""}
        onChange={(event) => column.setFilterValue(event.target.value)}
      />
      {column.getFilterValue() ? (
        <Button
          variant="ghost"
          size="icon"
          className="absolute right-0"
          onClick={() => column.setFilterValue("")}
        >
          <Icon path={mdiClose} size={0.8} />
        </Button>
      ) : null}
    </div>
  );
}
