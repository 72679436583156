import { Column } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { DocumentRowFragment } from "../client/generated";
import DocumentSortingButton from "./DocumentSortingButton";

type Props = {
  column: Column<DocumentRowFragment, string>;
};

export default function DocumentTitleTableHeader({
  column,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-1">
      <span>{t("DocumentTitleTableHeader.label")}</span>
      <DocumentSortingButton column={column} />
    </div>
  );
}
