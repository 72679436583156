import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { HeadingLevelPreviewFragment } from "../../client/generated";
import { Dispatch } from "react";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";

type Props = {
  fragment: HeadingLevelPreviewFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function HeadingLevelPreview({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      tabIdentifier={makeIdentifier(TabPrefix.HeadingLevelUpdate, fragment.id)}
      label={t("HeadingLevelPreview.label")}
      preview={t("HeadingLevelPreview.preview", { level: fragment.level })}
      dispatch={dispatch}
    />
  );
}
