import introspection from "./generated/introspection";
import { InMemoryCache } from "@apollo/client";

export const cache = new InMemoryCache({
  ...introspection,
  typePolicies: {
    Membership: {
      keyFields: ["document", ["id"], "user", ["id"]],
    },
  },
});
