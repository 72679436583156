import { MiniMap } from "react-zoom-pan-pinch";
import { PageMinimapFragment } from "../client/generated";
import Page from "./Page";

type Props = {
  fragment: PageMinimapFragment;
};

export default function PageMinimap({ fragment }: Props): JSX.Element {
  return (
    <div className="fixed z-10 top-4 right-4">
      <MiniMap borderColor="hsl(var(--brand))">
        <Page fragment={fragment} />
      </MiniMap>
    </div>
  );
}
