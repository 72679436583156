import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@acdc2/ui/components/avatar";
import { UserAvatarFragment } from "../client/generated";
import { useProtectedImageSource } from "../client/useProtectedImage";
import { ComponentProps } from "react";

type Props = ComponentProps<typeof Avatar> & {
  fragment: UserAvatarFragment;
};

export default function UserAvatar({
  fragment,
  ...avatarProps
}: Props): JSX.Element {
  const src = useProtectedImageSource(fragment.avatarUrl);

  return (
    <Avatar {...avatarProps}>
      <AvatarImage src={src} />
      <AvatarFallback>{fragment.username[0].toUpperCase()}</AvatarFallback>
    </Avatar>
  );
}
