import { Link } from "react-router";
import { routingPaths } from "../navigation/constants";
import { DocumentTitleTableCellFragment } from "../client/generated";
import { Icon } from "@mdi/react";
import { mdiAccountMultiple } from "@mdi/js";

type Props = {
  fragment: DocumentTitleTableCellFragment;
};

export default function DocumentTitleTableCell({
  fragment,
}: Props): JSX.Element {
  return (
    <Link
      to={routingPaths.document(fragment.id)}
      className="flex items-center gap-2"
    >
      <span>{fragment.name}</span>
      {fragment.members.total > 1 && (
        <Icon size="1rem" path={mdiAccountMultiple} />
      )}
    </Link>
  );
}
