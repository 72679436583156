import { RegionThumbnailFragment } from "../client/generated";
import RegionImage from "./RegionImage";

type Props = {
  fragment: RegionThumbnailFragment;
};

export default function RegionThumbnail({ fragment }: Props): JSX.Element {
  const viewBoxWidth = fragment.shape.aabb.width * fragment.page.width;
  const viewBoxHeight = fragment.shape.aabb.height * fragment.page.height;

  return (
    <div className="min-h-16 flex flex-col justify-center bg-gradient-to-r from-brand to-brand-square-90 p-2">
      <div
        className="mx-auto drop-shadow-lg"
        style={{ width: `${viewBoxWidth}in`, height: `${viewBoxHeight}in` }}
      >
        <RegionImage fragment={fragment} />
      </div>
    </div>
  );
}
