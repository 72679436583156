import { Dispatch } from "react";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import AttributePreview from "../AttributePreview";
import { TableOfContentsHeadingPreviewFragment } from "../../client/generated";
import { useTranslation } from "react-i18next";
type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: TableOfContentsHeadingPreviewFragment;
};

export default function TableOfContentsHeadingPreview({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      tabIdentifier={makeIdentifier(
        TabPrefix.TableOfContentsHeadingUpdate,
        fragment.id,
      )}
      dispatch={dispatch}
      label={t("TableOfContentsHeadingPreview.label")}
    />
  );
}
