import { Dispatch, useId, useState } from "react";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import { Textarea } from "@acdc2/ui/components/textarea";
import { AccessButton } from "@acdc2/ui/components/access-button";
import {
  ExpressionCreationTabFragment,
  useCreateExpressionAttributeMutation,
} from "../../client/generated";
import { useTranslation } from "react-i18next";
import KaTeXPreviewScrollArea from "./KaTeXPreviewScrollArea";
import { Label } from "@acdc2/ui/components/label";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: ExpressionCreationTabFragment;
};

export default function ExpressionCreationTab({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [latexExpression, setLatexExpression] = useState<string>();

  const previewAreaId = useId();
  const textAreaId = useId();

  const [createExpressionAttribute] = useCreateExpressionAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!latexExpression) return;

    await createExpressionAttribute({
      variables: { regionId: fragment.id, expressionLatex: latexExpression },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.ExpressionCreation, fragment.id)}
    >
      <NavbarTabHeader
        title={t("ExpressionCreationTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <div className="flex flex-col gap-2">
          <Label htmlFor={previewAreaId}>
            <span>{t("ExpressionCreationTab.labels.preview")}</span>
          </Label>
          <KaTeXPreviewScrollArea
            latexExpression={latexExpression ?? ""}
            id={previewAreaId}
          />
        </div>

        <div className="flex flex-col gap-2">
          <Label htmlFor={textAreaId}>
            <span>{t("ExpressionCreationTab.labels.textarea")}</span>
          </Label>
          <Textarea
            id={textAreaId}
            required
            placeholder={t("ExpressionCreationTab.placeholder")}
            value={latexExpression}
            onChange={(event) => setLatexExpression(event.target.value)}
          />
        </div>

        <AccessButton type="submit">
          {t("ExpressionCreationTab.labels.add")}
        </AccessButton>
      </form>
    </NavbarTabContent>
  );
}
