import { Dispatch } from "react";
import { SelectableRegionPreviewFragment } from "../client/generated";
import { EditorAction, EditorState } from "../editor/reducers";
import RegionThumbnail from "./RegionThumbnail";
import { cn } from "@acdc2/ui/lib/utils";
import AlternativeTextPreview from "../attributes/alternative-text/AlternativeTextPreview";
import CaptionPreview from "../attributes/caption/CaptionPreview";
import ExcludeReadingOrderPreview from "../attributes/exclude-reading-order/ExcludeReadingOrderPreview";
import ExpressionPreview from "../attributes/expression/ExpressionPreview";
import FigurePreview from "../attributes/figure/FigurePreview";
import HeadingLevelPreview from "../attributes/heading-level/HeadingLevelPreview";
import ImagePreview from "../attributes/image/ImagePreview";
import ListPreview from "../attributes/list/ListPreview";
import ListItemPreview from "../attributes/list-item/ListItemPreview";
import PaginationArtifactPreview from "../attributes/pagination-artifact/PaginationArtifactPreview";
import TablePreview from "../attributes/table/TablePreview";
import TableCellPreview from "../attributes/table-cell/TableCellPreview";
import TableHeaderCellPreview from "../attributes/table-header-cell/TableHeaderCellPreview";
import TableOfContentsPreview from "../attributes/table-of-contents/TableOfContentsPreview";
import TableOfContentsItemPreview from "../attributes/table-of-contents-item/TableOfContentsItemPreview";
import TextPreview from "../attributes/text/TextPreview";
import ListItemLabelPreview from "../attributes/list-item-label/ListItemLabelPreview";
import TableOfContentsHeadingPreview from "../attributes/table-of-contents-heading/TableOfContentsHeadingPreview";
import TableOfContentsPageNumberPreview from "../attributes/table-of-contents-page-number/TableOfContentsPageNumberPreview";
import AttributeCreationButton from "../attributes/AttributeCreationButton";
import RegionDeleteButton from "./RegionDeleteButton";

type Props = {
  dispatch: Dispatch<EditorAction>;
  state: EditorState;
  fragment: SelectableRegionPreviewFragment;
};

export default function SelectableRegionPreview({
  fragment,
  dispatch,
  state,
}: Props): JSX.Element {
  const isSelected = state.selectedRegionId === fragment.id;

  const onClick = () => {
    dispatch({
      type: "selectRegion",
      regionId: fragment.id,
    });
  };

  return (
    <div>
      <div
        className={cn(
          "border rounded overflow-hidden bg-gray-50 dark:bg-gray-900 cursor-pointer",
          {
            "outline outline-brand": isSelected,
          },
        )}
        onClick={onClick}
      >
        <div>
          <RegionThumbnail fragment={fragment} />
        </div>

        <div>
          {fragment.attributes.map((attribute) => (
            <div key={attribute.id} className="border-t">
              {attribute.__typename === "AlternativeTextAttribute" ? (
                <AlternativeTextPreview
                  fragment={attribute}
                  dispatch={dispatch}
                />
              ) : attribute.__typename === "CaptionAttribute" ? (
                <CaptionPreview fragment={attribute} dispatch={dispatch} />
              ) : attribute.__typename === "ExcludeReadingOrderAttribute" ? (
                <ExcludeReadingOrderPreview
                  fragment={attribute}
                  dispatch={dispatch}
                />
              ) : attribute.__typename === "ExpressionAttribute" ? (
                <ExpressionPreview fragment={attribute} dispatch={dispatch} />
              ) : attribute.__typename === "FigureAttribute" ? (
                <FigurePreview fragment={attribute} dispatch={dispatch} />
              ) : attribute.__typename === "HeaderLevelAttribute" ? (
                <HeadingLevelPreview fragment={attribute} dispatch={dispatch} />
              ) : attribute.__typename === "ImageAttribute" ? (
                <ImagePreview fragment={attribute} dispatch={dispatch} />
              ) : attribute.__typename === "ListAttribute" ? (
                <ListPreview fragment={attribute} dispatch={dispatch} />
              ) : attribute.__typename === "ListItemAttribute" ? (
                <ListItemPreview fragment={attribute} dispatch={dispatch} />
              ) : attribute.__typename === "ListItemLabelAttribute" ? (
                <ListItemLabelPreview
                  fragment={attribute}
                  dispatch={dispatch}
                />
              ) : attribute.__typename === "PaginationArtifactAttribute" ? (
                <PaginationArtifactPreview
                  fragment={attribute}
                  dispatch={dispatch}
                />
              ) : attribute.__typename === "TableAttribute" ? (
                <TablePreview fragment={attribute} dispatch={dispatch} />
              ) : attribute.__typename === "TableCellAttribute" ? (
                <TableCellPreview fragment={attribute} dispatch={dispatch} />
              ) : attribute.__typename === "TableHeaderCellAttribute" ? (
                <TableHeaderCellPreview
                  fragment={attribute}
                  dispatch={dispatch}
                />
              ) : attribute.__typename === "TableOfContentsAttribute" ? (
                <TableOfContentsPreview
                  fragment={attribute}
                  dispatch={dispatch}
                />
              ) : attribute.__typename === "TableOfContentsHeadingAttribute" ? (
                <TableOfContentsHeadingPreview
                  fragment={attribute}
                  dispatch={dispatch}
                />
              ) : attribute.__typename === "TableOfContentsItemAttribute" ? (
                <TableOfContentsItemPreview
                  fragment={attribute}
                  dispatch={dispatch}
                />
              ) : attribute.__typename ===
                "TableOfContentsPageNumberAttribute" ? (
                <TableOfContentsPageNumberPreview
                  fragment={attribute}
                  dispatch={dispatch}
                />
              ) : attribute.__typename === "TextAttribute" ? (
                <TextPreview fragment={attribute} dispatch={dispatch} />
              ) : null}
            </div>
          ))}
        </div>

        <div className="border-t flex p-2 gap-2">
          <RegionDeleteButton fragment={fragment} dispatch={dispatch} />
          <AttributeCreationButton fragment={fragment} dispatch={dispatch} />
        </div>
      </div>
    </div>
  );
}
