import { useTranslation } from "react-i18next";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@acdc2/ui/components/select";

type Props = React.ComponentProps<typeof Select>;

export default function HeaderLevelSelect(props: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Select {...props}>
      <SelectTrigger>
        <SelectValue placeholder={t("HeadingLevelSelect.placeholder")} />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="1">{t("HeadingLevelSelect.labels.h1")}</SelectItem>
        <SelectItem value="2">{t("HeadingLevelSelect.labels.h2")}</SelectItem>
        <SelectItem value="3">{t("HeadingLevelSelect.labels.h3")}</SelectItem>
        <SelectItem value="4">{t("HeadingLevelSelect.labels.h4")}</SelectItem>
        <SelectItem value="5">{t("HeadingLevelSelect.labels.h5")}</SelectItem>
        <SelectItem value="6">{t("HeadingLevelSelect.labels.h6")}</SelectItem>
      </SelectContent>
    </Select>
  );
}
