import { Button } from "@acdc2/ui/components/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@acdc2/ui/components/popover";
import { useState } from "react";
import { Icon } from "@mdi/react";
import { mdiUnfoldMoreHorizontal, mdiCheck } from "@mdi/js";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@acdc2/ui/components/command";
import { useTranslation } from "react-i18next";
import { DocumentExtensionComboboxFragment } from "../client/generated";

type Props = {
  fragment: DocumentExtensionComboboxFragment;
};

export default function DocumentExtensionCombobox({
  fragment,
}: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  const { t } = useTranslation();

  const extensions = fragment.extensions.map((extension) => {
    return { value: extension, label: extension };
  });

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="flex justify-between"
        >
          {value
            ? extensions.find((extension) => extension.value === value)?.label
            : t("DocumentExportCombobox.label")}
          <Icon path={mdiUnfoldMoreHorizontal} size={1} className="flex" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command>
          <CommandInput placeholder={t("DocumentExportCombobox.input")} />
          <CommandList>
            <CommandEmpty>
              {t("DocumentExportCombobox.results.placeholder")}
            </CommandEmpty>
            <CommandGroup>
              {extensions.map((extension) => (
                <CommandItem
                  key={extension.value}
                  value={extension.value}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                >
                  {value === extension.value ? (
                    <Icon path={mdiCheck} size={0.8} className="mr-2" />
                  ) : null}
                  {extension.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
