import { Dispatch, useState } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import {
  ListType,
  ListUpdateTabFragment,
  useUpdateListAttributeMutation,
} from "../../client/generated";
import ListTypeSelect from "./ListTypeSelect";
import AttributeDeleteButton from "../AttributeDeleteButton";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  fragment: ListUpdateTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function ListUpdateTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [listType, setListType] = useState(fragment.listType);

  const [updateListAttribute, { loading }] = useUpdateListAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateListAttribute({
      variables: {
        attributeId: fragment.id,
        listType,
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent value={makeIdentifier(TabPrefix.ListUpdate, fragment.id)}>
      <NavbarTabHeader
        title={t("ListUpdateTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <ListTypeSelect
          name="listType"
          value={listType}
          onValueChange={(newListType) => setListType(newListType as ListType)}
        />
        <div className="flex flex-col gap-2">
          <AccessButton loading={loading} type="submit">
            {t("ListUpdateTab.labels.confirm")}
          </AccessButton>
          <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
        </div>
      </form>
    </NavbarTabContent>
  );
}
