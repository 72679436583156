import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { ExcludeReadingOrderPreviewFragment } from "../../client/generated";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { Dispatch } from "react";

type Props = {
  fragment: ExcludeReadingOrderPreviewFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function ExcludeReadingOrderPreview({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      tabIdentifier={makeIdentifier(
        TabPrefix.ExcludeReadingOrderUpdate,
        fragment.id,
      )}
      label={t("ExcludeReadingOrderPreview.label")}
      dispatch={dispatch}
    />
  );
}
