import { Dispatch, useState } from "react";
import { Button } from "@acdc2/ui/components/button";
import { useTranslation } from "react-i18next";
import { Textarea } from "@acdc2/ui/components/textarea";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import {
  AlternativeTextCreationTabFragment,
  useCreateAlternativeTextAttributeMutation,
} from "../../client/generated";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: AlternativeTextCreationTabFragment;
};

export default function AlternativeTextCreationTab({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [alternativeText, setAlternativeText] = useState<string>();

  const [createAlternativeText] = useCreateAlternativeTextAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!alternativeText) return;

    await createAlternativeText({
      variables: { alternativeText, regionId: fragment.id },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.AlternativeTextCreation, fragment.id)}
    >
      <NavbarTabHeader
        title={t("AlternativeTextCreationTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <Textarea
          placeholder={t("AlternativeTextCreationTab.placeholder")}
          required
          value={alternativeText}
          onChange={(event) => setAlternativeText(event.target.value)}
          name="alternativeText"
        />
        <Button type="submit">
          {t("AlternativeTextCreationTab.labels.confirm")}
        </Button>
      </form>
    </NavbarTabContent>
  );
}
