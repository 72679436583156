import { HTMLAttributes } from "react";

type Props = HTMLAttributes<HTMLDivElement>;

export default function PageOverlay({ children, ...rest }: Props): JSX.Element {
  return (
    <div {...rest} className="absolute w-full h-full top-0 left-0">
      <svg className="w-full h-full">{children}</svg>
    </div>
  );
}
