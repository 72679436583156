import { ButtonIconLabel } from "@acdc2/ui/components/button-icon-label";
import { DropdownMenuItem } from "@acdc2/ui/components/dropdown-menu";
import { mdiAccountPlus } from "@mdi/js";
import { Trans, useTranslation } from "react-i18next";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { Suspense } from "react";
import { AccessDialogLoading } from "@acdc2/ui/components/access-loading";
import { DocumentShareCombobox } from "./DocumentShareCombobox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@acdc2/ui/components/dialog";
import {
  DocumentShareDrowdownMenuItemFragment,
  useDocumentShareDrodownMenuItemSuspenseQuery,
  useShareDocumentMutation,
  DocumentShareDrodownMenuItemDocument,
} from "../client/generated";
import DocumentShareCollaborator from "./DocumentShareCollaborator";
import DocumentSharePermission from "./DocumentSharePermission";

type Props = {
  fragment: DocumentShareDrowdownMenuItemFragment;
};

export default function DocumentShareDropdownMenuItem({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Dialog>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ButtonIconLabel
            path={mdiAccountPlus}
            description={t("DocumentShareDropdownMenuItem.label")}
          />
        </DropdownMenuItem>
      </DialogTrigger>
      <Suspense fallback={<AccessDialogLoading />}>
        <Body fragment={fragment} />
      </Suspense>
    </Dialog>
  );
}

function Body({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();

  const { data } = useDocumentShareDrodownMenuItemSuspenseQuery({
    variables: { documentId: fragment.id },
  });

  const [shareDocument] = useShareDocumentMutation();

  const handleShare = async (userId: string) => {
    try {
      await shareDocument({
        variables: {
          documentId: fragment.id,
          userId,
        },
        refetchQueries: [DocumentShareDrodownMenuItemDocument],
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (data.document?.__typename !== "Document") {
    throw TypeError("Document not found");
  }

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          {t("DocumentShareDropdownMenuItem.dialog.title")}
        </DialogTitle>
        <DialogDescription>
          <Trans
            i18nKey="DocumentRenameDropdownMenuItem.dialog.description"
            components={{ "word-break": <span className="break-all" /> }}
            values={{ name: data.document.name }}
          />
        </DialogDescription>
      </DialogHeader>

      <DocumentShareCombobox
        fragment={data.viewer.colleagues}
        onSelect={handleShare}
      />

      <div className="flex flex-col gap-4">
        <h3 className="text-lg font-semibold leading-none tracking-tight">
          {t("DocumentShareDropdownMenuItem.dialog.collaborators.title")}
        </h3>
        <ul className="-mx-6 flex flex-col gap-4">
          {data.document.members.memberships.map((membership) => (
            <li key={membership.user.id} className="px-6">
              <DocumentShareCollaborator
                fragment={membership.user}
                right={<DocumentSharePermission fragment={membership} />}
              />
            </li>
          ))}
        </ul>
      </div>

      <DialogFooter>
        <DialogClose asChild>
          <AccessButton>
            {t("DocumentShareDropdownMenuItem.dialog.done")}
          </AccessButton>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  );
}
