import { DocumentExtensionTableCellFragment } from "../client/generated";

type Props = {
  fragment: DocumentExtensionTableCellFragment;
};

export default function DocumentExtensionTableCell({
  fragment,
}: Props): JSX.Element {
  return <div>{fragment.extension.toUpperCase()}</div>;
}
