import { mdiSwapHorizontalVariant } from "@mdi/js";
import { useTranslation } from "react-i18next";
import NavbarTabButton from "../shell/NavbarTabButton";
import { TabIdentifier } from "./reducers";

type Props = {
  disabled?: boolean;
};

export default function EditorRegionOrderNavbarTabButton({
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabButton
      tooltip={t("EditorReadingOrderNavbarTabButton.tooltip")}
      value={TabIdentifier.ReadingOrder}
      iconPath={mdiSwapHorizontalVariant}
      disabled={disabled}
    />
  );
}
