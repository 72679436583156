import { Dispatch, useState } from "react";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import {
  ListItemUpdateTabFragment,
  useUpdateListItemAttributeMutation,
} from "../../client/generated";
import { Input } from "@acdc2/ui/components/input";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import AttributeDeleteButton from "../AttributeDeleteButton";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  fragment: ListItemUpdateTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function ListItemUpdateTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [ordinal, setOrdinal] = useState(fragment.ordinal);

  const [updateListItemAttribute, { loading }] =
    useUpdateListItemAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateListItemAttribute({
      variables: {
        attributeId: fragment.id,
        ordinal: ordinal,
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.ListItemUpdate, fragment.id)}
    >
      <NavbarTabHeader
        title={t("ListItemUpdateTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <Input
          type="number"
          inputMode="numeric"
          min={1}
          value={ordinal}
          onChange={(event) => setOrdinal(parseInt(event.target.value))}
          required
        />
        <div className="flex flex-col gap-2">
          <AccessButton loading={loading} type="submit">
            {t("ListItemUpdateTab.labels.confirm")}
          </AccessButton>
          <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
        </div>
      </form>
    </NavbarTabContent>
  );
}
