import { Dispatch } from "react";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { useTranslation } from "react-i18next";
import { TableCreationTabFragment } from "../../client/generated";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  fragment: TableCreationTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function TableCreationTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.TableCreation, fragment.id)}
    >
      <NavbarTabHeader
        title={t("TableCreationTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      Table Creation Tab
    </NavbarTabContent>
  );
}
