import { useTranslation } from "react-i18next";
import { Dispatch } from "react";
import { EditorAction, EditorState, TabIdentifier } from "./reducers";
import SidebarDivider from "../shell/SidebarDivider";
import NavbarTabContent from "../shell/NavbarTabContent";
import RegionsToolbox from "../regions/RegionsToolbox";
import { EditorRegionsNavbarTabContentFragment } from "../client/generated";
import SelectableRegionPreview from "../regions/SelectableRegionPreview";
import { ScrollArea } from "@acdc2/ui/components/scroll-area";
import NavbarTabHeader from "../shell/NavbarTabHeader";

type Props = {
  state: EditorState;
  dispatch: Dispatch<EditorAction>;
  fragment: EditorRegionsNavbarTabContentFragment;
};

export default function EditorRegionsNavbarTabContent({
  state,
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const sortedRegions = [...fragment.regions].sort((a, b) => {
    const aTop = a.shape.aabb.top;
    const bTop = b.shape.aabb.top;
    if (aTop !== bTop) return aTop - bTop;

    const aLeft = a.shape.aabb.left;
    const bLeft = b.shape.aabb.left;
    return aLeft - bLeft;
  });

  return (
    <NavbarTabContent value={TabIdentifier.Regions}>
      <NavbarTabHeader title={t("EditorRegionsNavbarTabContent.title")} />
      <div>
        <div className="p-4">
          <RegionsToolbox state={state} dispatch={dispatch} />
        </div>
        <SidebarDivider />
      </div>
      <ScrollArea className="flex-1">
        <div className="flex flex-col gap-4 p-4">
          {sortedRegions.map((region) => (
            <SelectableRegionPreview
              key={region.id}
              fragment={region}
              state={state}
              dispatch={dispatch}
            />
          ))}
        </div>
      </ScrollArea>
    </NavbarTabContent>
  );
}
