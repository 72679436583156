import { Dispatch } from "react";
import AttributePreview from "../AttributePreview";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { TableOfContentsPageNumberPreviewFragment } from "../../client/generated";
import { useTranslation } from "react-i18next";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: TableOfContentsPageNumberPreviewFragment;
};

export default function TableOfContentsPageNumberPreview({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <AttributePreview
      tabIdentifier={makeIdentifier(
        TabPrefix.TableOfContentsPageNumberUpdate,
        fragment.id,
      )}
      dispatch={dispatch}
      label={t("TableOfContentsPageNumberPreview.label")}
    />
  );
}
