import { useNavigate } from "react-router";
import { mdiViewGridOutline } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { OverviewNavbarButtonFragment } from "../client/generated";
import NavbarButton from "../shell/NavbarButton";
import NavbarButtonIcon from "../shell/NavbarButtonIcon";
import { routingPaths } from "../navigation/constants";

type Props = {
  fragment: OverviewNavbarButtonFragment;
  disabled?: boolean;
};

export default function OverviewNavbarButton({
  fragment,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = () => {
    navigate(routingPaths.document(fragment.id));
  };

  return (
    <NavbarButton tooltip={t("OverviewNavbarButton.tooltip")}>
      <NavbarButtonIcon
        path={mdiViewGridOutline}
        onClick={onClick}
        disabled={disabled}
      />
    </NavbarButton>
  );
}
