import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@acdc2/ui/components/select";
import { TableOfContentRegionSelectFragment } from "../../client/generated";
import { useTranslation } from "react-i18next";

type Props = React.ComponentProps<typeof Select> & {
  fragment: TableOfContentRegionSelectFragment;
};

export default function TableOfContentRegionSelect({
  fragment,
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Select {...props}>
      <SelectTrigger>
        <SelectValue
          placeholder={t("TableOfContentRegionSelect.placeholder")}
        />
      </SelectTrigger>
      <SelectContent>
        {fragment.page.regions.map((region) => (
          <SelectItem key={region.id} value={region.id}>
            {region.id}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
