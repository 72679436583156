import { ButtonIconLabel } from "@acdc2/ui/components/button-icon-label";
import { mdiCog } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { DropdownMenuItem } from "@acdc2/ui/components/dropdown-menu";
import { Link } from "react-router";
import { routingPaths } from "../navigation/constants";

export default function ViewerSettingsDropdownMenuItem(): JSX.Element {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem>
      <Link to={routingPaths.settings}>
        <ButtonIconLabel
          path={mdiCog}
          description={t("ViewerSettingsDropdownMenuItem.label")}
        />
      </Link>
    </DropdownMenuItem>
  );
}
