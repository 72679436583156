import { PageFragment } from "../client/generated";
import { useProtectedImageSource } from "../client/useProtectedImage";
import { CSS_IN_TO_PX } from "./utils";
import PageSkeleton from "./PageSkeleton";

type Props = {
  fragment: PageFragment;
};

export default function Page({ fragment }: Props): JSX.Element {
  const src = useProtectedImageSource(fragment.svgUrl);
  const pageWidth = fragment.width * CSS_IN_TO_PX;
  const pageHeight = fragment.height * CSS_IN_TO_PX;

  return (
    <div
      className="bg-white shadow"
      style={{ width: `${pageWidth}px`, height: `${pageHeight}px` }}
    >
      {src ? (
        <img src={src} className="h-full" />
      ) : (
        <PageSkeleton fragment={fragment} />
      )}
    </div>
  );
}
