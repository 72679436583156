import { useTranslation } from "react-i18next";
import { AlternativeTextPreviewFragment } from "../../client/generated";
import AttributePreview from "../AttributePreview";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { Dispatch } from "react";

type Props = {
  fragment: AlternativeTextPreviewFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function AlternativeTextPreview({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      tabIdentifier={makeIdentifier(
        TabPrefix.AlternativeTextUpdate,
        fragment.id,
      )}
      label={t("AlternativeTextPreview.label")}
      preview={fragment.alternativeText}
      dispatch={dispatch}
    />
  );
}
