import { DocumentModifiedByTableCellFragment } from "../client/generated";
import UserAvatar from "../users/UserAvatar";

type Props = {
  fragment: DocumentModifiedByTableCellFragment;
};

export default function DocumentModifiedByTableCell({
  fragment,
}: Props): JSX.Element {
  return (
    <div className="flex items-center gap-2">
      <UserAvatar className="h-6 w-6" fragment={fragment.lastModifiedBy} />
      <span>{fragment.lastModifiedBy.username}</span>
    </div>
  );
}
