import { Dispatch, useState } from "react";
import { Textarea } from "@acdc2/ui/components/textarea";
import { Button } from "@acdc2/ui/components/button";
import { useTranslation } from "react-i18next";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import {
  TextCreationTabFragment,
  useCreateTextAttributeMutation,
} from "../../client/generated";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: TextCreationTabFragment;
};

export default function TextCreationTab({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [textAttribute, setTextAttribute] = useState<string>();

  const [createTextAttribute] = useCreateTextAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!textAttribute) return;

    await createTextAttribute({
      variables: { text: textAttribute, regionId: fragment.id },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.TextCreation, fragment.id)}
    >
      <NavbarTabHeader
        title={t("TextCreationTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <Textarea
          placeholder={t("TextCreationTab.placeholder")}
          required
          value={textAttribute}
          onChange={(event) => setTextAttribute(event.target.value)}
          name="text"
        />
        <Button type="submit">{t("TextCreationTab.labels.confirm")}</Button>
      </form>
    </NavbarTabContent>
  );
}
