import { Dispatch } from "react";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import {
  TableOfContentsPageNumberCreationTabFragment,
  useCreateTableOfContentsPageNumberAttributeMutation,
} from "../../client/generated";
import { useTranslation } from "react-i18next";
import { AccessButton } from "@acdc2/ui/components/access-button";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: TableOfContentsPageNumberCreationTabFragment;
};
export default function TableOfContentsPageNumberCreationTab({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [createToCPageNumberAttribute] =
    useCreateTableOfContentsPageNumberAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await createToCPageNumberAttribute({
      variables: { regionId: fragment.id },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(
        TabPrefix.TableOfContentsPageNumberCreation,
        fragment.id,
      )}
    >
      <NavbarTabHeader
        title={t("TableOfContentsPageNumberCreationTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col" onSubmit={onSubmit}>
        <AccessButton>
          {t("TableOfContentsPageNumberCreationTab.labels.add")}
        </AccessButton>
      </form>
    </NavbarTabContent>
  );
}
