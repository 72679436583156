import { useTranslation } from "react-i18next";
import { TabIdentifier } from "./reducers";
import NavbarTabContent from "../shell/NavbarTabContent";
import NavbarTabHeader from "../shell/NavbarTabHeader";

export default function EditorCheckerNavbarTabContent(): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent value={TabIdentifier.Checker}>
      <NavbarTabHeader title={t("EditorCheckerNavbarTabContent.title")} />
      Accessibility Checker Content
    </NavbarTabContent>
  );
}
