import { Dispatch, useState } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import { Textarea } from "@acdc2/ui/components/textarea";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import {
  AlternativeTextUpdateTabFragment,
  useUpdateAlternativeTextAttributeMutation,
} from "../../client/generated";
import AttributeDeleteButton from "../AttributeDeleteButton";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  fragment: AlternativeTextUpdateTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function AlternativeTextUpdateTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [alternativeText, setAlternativeText] = useState(
    fragment.alternativeText,
  );

  const [alternativeTextUpdate, { loading }] =
    useUpdateAlternativeTextAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await alternativeTextUpdate({
      variables: { attributeId: fragment.id, alternativeText },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.AlternativeTextUpdate, fragment.id)}
    >
      <NavbarTabHeader
        title={t("AlternativeTextUpdateTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <Textarea
          required
          value={alternativeText}
          onChange={(event) => setAlternativeText(event.target.value)}
          rows={8}
        />
        <div className="flex flex-col gap-2">
          <AccessButton type="submit" loading={loading}>
            {t("AlternativeTextUpdateTab.labels.confirm")}
          </AccessButton>
          <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
        </div>
      </form>
    </NavbarTabContent>
  );
}
