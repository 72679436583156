import { mdiTagOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { EditorAction } from "../editor/reducers";
import { Dispatch } from "react";

type Props = {
  tabIdentifier: string;
  label: string;
  preview?: string;
  dispatch: Dispatch<EditorAction>;
};

export default function AttributePreview({
  tabIdentifier,
  label,
  preview,
  dispatch,
}: Props): JSX.Element {
  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch({
      type: "switchTab",
      tabIdentifier,
    });
  };

  return (
    <div className="flex">
      <div
        className="flex items-center gap-1 p-2 border-r cursor-pointer hover:text-brand"
        onClick={onClick}
      >
        <Icon path={mdiTagOutline} size="1rem" />
        <span className="font-bold text-xs uppercase">{label}</span>
      </div>
      <div className="p-2 flex-1 truncate text-right text-xs">{preview}</div>
    </div>
  );
}
