import { Dispatch, useState } from "react";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import { useTranslation } from "react-i18next";
import { AccessButton } from "@acdc2/ui/components/access-button";
import TabelCellRowInput from "./TabelCellRowInput";
import TableCellColumnInput from "./TableCellColumnInput";
import {
  TableCellCreationTabFragment,
  useCreateTableCellMutation,
} from "../../client/generated";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: TableCellCreationTabFragment;
};

export default function TableCellCreationTab({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [rowSpan, setRowSpan] = useState<number>();
  const [columnSpan, setColumnSpan] = useState<number>();

  const [createTableCell] = useCreateTableCellMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!rowSpan || !columnSpan) return;

    await createTableCell({
      variables: {
        regionId: fragment.id,
        rowSpan,
        columnSpan,
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.TableCellCreation, fragment.id)}
    >
      <NavbarTabHeader
        title={t("TableCellCreationTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <TabelCellRowInput
          value={rowSpan ?? ""}
          onChange={(event) => setRowSpan(parseInt(event.target.value))}
        />
        <TableCellColumnInput
          value={columnSpan ?? ""}
          onChange={(event) => setColumnSpan(parseInt(event.target.value))}
        />
        <AccessButton type="submit">
          {t("TableCellCreationTab.labels.add")}
        </AccessButton>
      </form>
    </NavbarTabContent>
  );
}
