import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@acdc2/ui/components/accordion";
import { Button } from "@acdc2/ui/components/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@acdc2/ui/components/dialog";
import { Input } from "@acdc2/ui/components/input";
import { Label } from "@acdc2/ui/components/label";
import { useTranslation } from "react-i18next";
import { MetadataTitleAccordionItemFragment } from "../client/generated";
import MetadataTitleLabel from "./MetadataTitleLabel";

type Props = {
  fragment: MetadataTitleAccordionItemFragment;
};

export default function MetadataKeywordAccordionItem({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AccordionItem value="titles">
      <AccordionTrigger className="mx-2">
        {t("MetadataTitleAccordionItem.label")}
      </AccordionTrigger>
      {fragment.metadata.titles.map((title, id) => (
        <AccordionContent className="mx-2" key={id}>
          <Dialog>
            <DialogTrigger asChild>
              <Button variant="ghost" className="w-full justify-start">
                <MetadataTitleLabel fragment={title} />
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>
                  {t("MetadataTitleAccordionItem.dialog.title")}
                </DialogTitle>
                <DialogDescription>
                  {t("MetadataTitleAccordionItem.dialog.description")}
                </DialogDescription>
              </DialogHeader>

              <div className="flex flex-col gap-4">
                <Label htmlFor="language" className="flex flex-col gap-2">
                  {t("MetadataTitleAccordionItem.dialog.languageInput.label")}
                  <Input
                    id="language"
                    defaultValue={title.language}
                    placeholder={t(
                      "MetadataTitleAccordionItem.dialog.languageInput.placeholder",
                    )}
                  />
                </Label>
                <Label htmlFor="title" className="flex flex-col gap-2">
                  {t("MetadataTitleAccordionItem.dialog.titleInput.label")}
                  <Input
                    id="title"
                    defaultValue={title.title}
                    placeholder={t(
                      "MetadataTitleAccordionItem.dialog.titleInput.placeholder",
                    )}
                  />
                </Label>
              </div>

              <DialogFooter>
                <Button variant="destructive">
                  {t("MetadataTitleAccordionItem.dialog.buttons.delete")}
                </Button>
                <Button type="submit">
                  {t("MetadataTitleAccordionItem.dialog.buttons.save")}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </AccordionContent>
      ))}
    </AccordionItem>
  );
}
