import { useState } from "react";
import {
  PageScreenDocument,
  RegionsToolOverlayFragment,
  useCreateRegionMutation,
} from "../client/generated";
import PageOverlay from "../editor/PageOverlay";
import { CSS_IN_TO_PX, toPercent } from "../editor/utils";
import { EditorAction, EditorState, RegionMode } from "../editor/reducers";
import ReshapeableRegion from "./ReshapeableRegion";
import TransformableRegion from "./TransformableRegion";

type Props = {
  state: EditorState;
  fragment: RegionsToolOverlayFragment;
  dispatch: React.Dispatch<EditorAction>;
};

type Point = {
  x: number;
  y: number;
};

export default function RegionsToolOverlay({
  state,
  fragment,
  dispatch,
}: Props): JSX.Element {
  const pageWidth = fragment.width * CSS_IN_TO_PX;
  const pageHeight = fragment.height * CSS_IN_TO_PX;
  const [startCoords, setStartCoords] = useState<Point>();
  const [endCoords, setEndCoords] = useState<Point>();
  const [mutate] = useCreateRegionMutation({
    refetchQueries: [PageScreenDocument],
  });

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    dispatch({ type: "deselectRegion" });
    setEndCoords(undefined);
    setStartCoords({
      x: event.nativeEvent.offsetX / pageWidth,
      y: event.nativeEvent.offsetY / pageHeight,
    });
  };

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!startCoords || event.buttons !== 1) return;
    setEndCoords({
      x: event.nativeEvent.offsetX / pageWidth,
      y: event.nativeEvent.offsetY / pageHeight,
    });
  };

  const onMouseUp = async () => {
    setStartCoords(undefined);
    setEndCoords(undefined);

    if (!startCoords || !endCoords) return;

    const { data, errors } = await mutate({
      variables: {
        pageId: fragment.id,
        vertices: [
          {
            left: Math.min(startCoords.x, endCoords.x),
            top: Math.min(startCoords.y, endCoords.y),
          },
          {
            left: Math.min(startCoords.x, endCoords.x),
            top: Math.max(startCoords.y, endCoords.y),
          },
          {
            left: Math.max(startCoords.x, endCoords.x),
            top: Math.max(startCoords.y, endCoords.y),
          },
          {
            left: Math.max(startCoords.x, endCoords.x),
            top: Math.min(startCoords.y, endCoords.y),
          },
        ],
      },
    });

    if (errors) {
      console.log("Error creating region", errors);
    } else if (data?.createRegion.userErrors) {
      console.log("User error creating region", data.createRegion.userErrors);
    }
  };

  const regionsSortedByFocusPriority = fragment.regions.slice().sort((a, b) => {
    if (a.id === state.selectedRegionId) return 1;
    if (b.id === state.selectedRegionId) return -1;

    const areaA = a.shape.aabb.height * a.shape.aabb.width;
    const areaB = b.shape.aabb.height * b.shape.aabb.width;
    return areaB - areaA;
  });

  return (
    <PageOverlay
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      {state.mode === RegionMode.Rectangle &&
        regionsSortedByFocusPriority.map((region) => (
          <TransformableRegion
            key={region.id}
            fragment={region}
            isSelected={state.selectedRegionId === region.id}
            dispatch={dispatch}
          />
        ))}

      {state.mode === RegionMode.Polygon &&
        regionsSortedByFocusPriority.map((region) => (
          <ReshapeableRegion
            key={region.id}
            fragment={region}
            isSelected={state.selectedRegionId === region.id}
            dispatch={dispatch}
          />
        ))}

      {startCoords && endCoords && (
        <rect
          x={toPercent(Math.min(startCoords.x, endCoords.x))}
          y={toPercent(Math.min(startCoords.y, endCoords.y))}
          width={toPercent(Math.abs(endCoords.x - startCoords.x))}
          height={toPercent(Math.abs(endCoords.y - startCoords.y))}
          stroke="hsl(var(--brand))"
          fill="transparent"
        />
      )}
    </PageOverlay>
  );
}
