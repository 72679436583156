import { initReactI18next } from "react-i18next";
import * as i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import EN from "../locales/en.json";
import DE from "../locales/de.json";
import "dayjs/locale/de";
import "dayjs/locale/en";

export const resources = {
  en: { translation: EN },
  de: { translation: DE },
};

const languages = Object.keys(resources);

i18next.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  supportedLngs: languages,
  appendNamespaceToCIMode: true,
});
