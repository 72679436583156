import { Dispatch } from "react";
import AttributeDeleteButton from "../AttributeDeleteButton";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { TableOfContentsHeadingUpdateTabFragment } from "../../client/generated";
import { useTranslation } from "react-i18next";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: TableOfContentsHeadingUpdateTabFragment;
};

export default function TableOfContentsHeadingUpdateTab({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={makeIdentifier(
        TabPrefix.TableOfContentsHeadingUpdate,
        fragment.id,
      )}
    >
      <NavbarTabHeader
        title={t("TableOfContentsHeadingUpdateTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <div className="flex flex-col p-4">
        <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
      </div>
    </NavbarTabContent>
  );
}
