import { Dispatch } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import {
  ExcludeReadingOrderCreationTabFragment,
  useCreateExcludeReadingOrderAttributeMutation,
} from "../../client/generated";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: ExcludeReadingOrderCreationTabFragment;
};

export default function ExcludeReadingOrderCreationTab({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createExcludeReadingOrderAttribute] =
    useCreateExcludeReadingOrderAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await createExcludeReadingOrderAttribute({
      variables: { regionId: fragment.id },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.ExcludeReadingOrderCreation, fragment.id)}
    >
      <NavbarTabHeader
        title={t("ExcludeReadingOrderCreationTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col" onSubmit={onSubmit}>
        <AccessButton type="submit">
          {t("ExcludeReadingOrderCreationTab.labels.add")}
        </AccessButton>
      </form>
    </NavbarTabContent>
  );
}
