import * as React from "react";
import { Icon } from "@mdi/react";
import { mdiAccountSearch } from "@mdi/js";
import { Button } from "@acdc2/ui/components/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@acdc2/ui/components/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@acdc2/ui/components/popover";
import { DocumentShareComboxboxFragment } from "../client/generated";
import { useTranslation } from "react-i18next";
import DocumentShareCollaborator from "./DocumentShareCollaborator";
import { AccessLoading } from "@acdc2/ui/components/access-loading";

type Props = {
  fragment: DocumentShareComboxboxFragment;
  onSelect: (value: string) => Promise<void>;
};

export function DocumentShareCombobox({
  fragment,
  onSelect,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [loadingId, setLoadingId] = React.useState<string>();
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          <span>{t("DocumentShareCombobox.label")}</span>
          <Icon path={mdiAccountSearch} size={1} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command>
          <CommandInput placeholder={t("DocumentShareCombobox.placeholder")} />
          <CommandList>
            <CommandEmpty>{t("DocumentShareCombobox.empty")}</CommandEmpty>
            <CommandGroup>
              {fragment.users.map((user) => (
                <CommandItem
                  key={user.id}
                  value={user.username}
                  onSelect={async () => {
                    setLoadingId(user.id);
                    try {
                      await onSelect(user.id);
                      setOpen(false);
                    } finally {
                      setLoadingId(undefined);
                    }
                  }}
                  className="flex flex-col items-stretch"
                >
                  <DocumentShareCollaborator
                    fragment={user}
                    right={
                      loadingId === user.id && <AccessLoading className="h-8" />
                    }
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
