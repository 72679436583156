import { Dispatch, useState } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import {
  PaginationArtifactType,
  PaginationArtifactUpdateTabFragment,
  useUpdatePaginationArtifactAttributeMutation,
} from "../../client/generated";
import PaginationArtifactTypeSelect from "./PaginationArtifactTypeSelect";
import AttributeDeleteButton from "../AttributeDeleteButton";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  fragment: PaginationArtifactUpdateTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function PaginationArtifactUpdateTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [artifactType, setArtifactType] = useState(fragment.type);

  const [updatePaginationArtifact, { loading }] =
    useUpdatePaginationArtifactAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updatePaginationArtifact({
      variables: {
        attributeId: fragment.id,
        paginationArtifactType: artifactType as PaginationArtifactType,
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.PaginationArtifactUpdate, fragment.id)}
    >
      <NavbarTabHeader
        title={t("PaginationArtifactUpdateTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <PaginationArtifactTypeSelect
          value={artifactType}
          onValueChange={(newArtifactType) =>
            setArtifactType(newArtifactType as PaginationArtifactType)
          }
        />
        <div className="flex flex-col gap-2">
          <AccessButton type="submit" loading={loading}>
            {t("PaginationArtifactUpdateTab.labels.confirm")}
          </AccessButton>
          <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
        </div>
      </form>
    </NavbarTabContent>
  );
}
