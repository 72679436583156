import * as Tabs from "@radix-ui/react-tabs";
import { Suspense } from "react";
import SidebarLoading from "./SidebarLoading";

type Props = {
  value: string;
  children?: React.ReactNode;
};

export default function NavbarTabContent({
  value,
  children,
}: Props): JSX.Element {
  return (
    <Tabs.Content value={value} asChild>
      <div className="h-full flex flex-col">
        <Suspense fallback={<SidebarLoading />}>{children}</Suspense>
      </div>
    </Tabs.Content>
  );
}
