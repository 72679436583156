import { Icon } from "@mdi/react";
import { useTranslation } from "react-i18next";
import { Dispatch } from "react";
import { Tabs, TabsList, TabsTrigger } from "@acdc2/ui/components/tabs";
import { mdiVectorPolygonVariant, mdiVectorSquare } from "@mdi/js";
import { EditorAction, EditorState, RegionMode } from "../editor/reducers";

type Props = {
  state: EditorState;
  dispatch: Dispatch<EditorAction>;
};

export default function RegionsToolbox({
  state,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const onValueChange = (mode: string) => {
    dispatch({ type: "switchMode", mode: mode as RegionMode });
  };

  return (
    <Tabs value={state.mode} onValueChange={onValueChange}>
      <TabsList className="w-full grid grid-cols-2">
        <TabsTrigger value={RegionMode.Rectangle}>
          <Icon path={mdiVectorSquare} className="w-4 h-4" />
          <span className="ml-2">{t("RegionsToolbox.rectangle.label")}</span>
        </TabsTrigger>
        <TabsTrigger value={RegionMode.Polygon}>
          <Icon path={mdiVectorPolygonVariant} className="w-4 h-4" />
          <span className="ml-2">{t("RegionsToolbox.polygon.label")}</span>
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
}
