import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SortableRegion from "./SortableRegion";
import { SortableRegionFragment } from "../client/generated";
import { AccessSeparator } from "@acdc2/ui/components/access-separator";

type Props = {
  id: string;
  items: SortableRegionFragment[];
  title: string;
};

export default function RegionDropzone({ id, items, title }: Props) {
  const { setNodeRef } = useDroppable({ id });

  return (
    <div className="flex flex-col gap-4">
      <AccessSeparator title={title} />
      <SortableContext
        id={id}
        items={items.map((region) => region.id)}
        strategy={verticalListSortingStrategy}
      >
        <div ref={setNodeRef} className="flex flex-col gap-4">
          {items.map((region) => (
            <SortableRegion key={region.id} fragment={region} />
          ))}
        </div>
      </SortableContext>
    </div>
  );
}
