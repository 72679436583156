import { Dispatch, useState } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import {
  ListCreationTabFragment,
  ListType,
  useCreateListAttributeMutation,
} from "../../client/generated";
import ListTypeSelect from "./ListTypeSelect";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: ListCreationTabFragment;
};

export default function ListCreationTab({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [listType, setListType] = useState<string>();
  const [createListAttribute] = useCreateListAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!listType) return;

    await createListAttribute({
      variables: {
        regionId: fragment.id,
        listType: listType as ListType,
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.ListCreation, fragment.id)}
    >
      <NavbarTabHeader
        title={t("ListCreationTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <ListTypeSelect value={listType} onValueChange={setListType} required />
        <AccessButton type="submit">
          {t("ListCreationTab.labels.confirm")}
        </AccessButton>
      </form>
    </NavbarTabContent>
  );
}
