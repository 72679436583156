import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { SortableRegionFragment } from "../client/generated";
import SortableRegionPreview from "./SortableRegionPreview";
import { cn } from "@acdc2/ui/lib/utils";

type Props = {
  fragment: SortableRegionFragment;
};

export default function SortableRegion({ fragment }: Props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: fragment.id, data: fragment });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className={cn({ "opacity-50": isDragging })}>
        <SortableRegionPreview fragment={fragment} />
      </div>
    </div>
  );
}
