import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@acdc2/ui/components/select";
import React from "react";
import { CaptionRegionSelectFragment } from "../../client/generated";
import { useTranslation } from "react-i18next";

type Props = React.ComponentProps<typeof Select> & {
  fragment: CaptionRegionSelectFragment;
};

export default function CaptionRegionSelect({
  fragment,
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Select {...props}>
      <SelectTrigger>
        <SelectValue placeholder={t("CaptionRegionSelect.placeholder")} />
      </SelectTrigger>
      <SelectContent>
        {fragment.page.regions.map((region) => (
          <SelectItem value={region.id} key={region.id}>
            {region.id}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
