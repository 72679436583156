import { Icon } from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { Button } from "@acdc2/ui/components/button";
import { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@acdc2/ui/components/tooltip";
import { EditorAction, TabIdentifier } from "../editor/reducers";

type Props = {
  dispatch: Dispatch<EditorAction>;
};

export default function AttributeCloseButton({ dispatch }: Props): JSX.Element {
  const { t } = useTranslation();

  const onClick = () => {
    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button variant="outline" size="icon" onClick={onClick}>
            <Icon path={mdiClose} size={1} />
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">
          {t("AttributeCloseButton.tooltip")}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
