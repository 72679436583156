import * as i18next from "i18next";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ApolloLink, createHttpLink, from } from "@apollo/client";
import { User } from "oidc-client-ts";
import { toast } from "sonner";
import { translateUserError } from "../i18n/translateError";

const userErrorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const data = response.data ?? {};
    const queriesAndMutations = Object.values(data);
    const userErrors = queriesAndMutations
      .map((field) => field.userErrors)
      .flat()
      .filter(Boolean);

    if (userErrors.length > 0) {
      const firstError = userErrors[0];
      toast.error(translateUserError(firstError.code));
    }

    return response;
  });
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      toast.error(i18next.t("links.errors.graphql"), { description: message });
    });
  }

  if (networkError) {
    toast.error(i18next.t("links.errors.network"), {
      description: networkError.message,
    });
  }
});

const authLink = setContext((_, { headers }) => {
  const authority = import.meta.env.VITE_ACDC2_OIDC_AUTHORITY;
  const clientId = import.meta.env.VITE_ACDC2_OIDC_CLIENT_ID;
  const key = `oidc.user:${authority}:${clientId}`;
  const oidcStorage = window.localStorage.getItem(key);
  const user = oidcStorage ? User.fromStorageString(oidcStorage) : null;
  const token = user?.access_token;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_ACDC2_GRAPHQL_URL,
});

export const link = from([userErrorLink, errorLink, authLink, httpLink]);
