import { Dispatch, useState } from "react";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import { Input } from "@acdc2/ui/components/input";
import { AccessButton } from "@acdc2/ui/components/access-button";
import {
  ListItemCreationTabFragment,
  useCreateListItemAttributeMutation,
} from "../../client/generated";
import { useTranslation } from "react-i18next";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: ListItemCreationTabFragment;
};

export default function ListItemCreationTab({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [ordinal, setOrdinal] = useState<number>();

  const [createListItem] = useCreateListItemAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!ordinal) return;

    await createListItem({
      variables: {
        regionId: fragment.id,
        ordinal: ordinal,
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.ListItemCreation, fragment.id)}
    >
      <NavbarTabHeader
        title={t("ListItemCreationTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <Input
          placeholder={t("ListItemCreationTab.placeholder")}
          type="number"
          inputMode="numeric"
          min={1}
          value={ordinal}
          onChange={(event) => setOrdinal(parseInt(event.target.value))}
          required
        />
        <AccessButton type="submit">
          {t("ListItemCreationTab.labels.add")}
        </AccessButton>
      </form>
    </NavbarTabContent>
  );
}
