import { useState } from "react";
import {
  ColumnFiltersState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  DocumentRowFragment,
  DocumentTableFragment,
} from "../client/generated";
import DocumentUploadButton from "./DocumentUploadButton";
import DocumentUploadedByTableHeader from "./DocumentUploadedByTableHeader";
import DocumentTitleTableHeader from "./DocumentTitleTableHeader";
import DocumentModifiedAtTableHeader from "./DocumentModifiedAtTableHeader";
import DocumentExtensionTableHeader from "./DocumentExtensionTableHeader";
import DocumentModifiedByTableHeader from "./DocumentModifiedByTableHeader";
import DocumentTableContent from "./DocumentTableContent";
import DocumentUploadedAtTableHeader from "./DocumentUploadedAtTableHeader";
import DocumentTableActionsDropdownMenu from "./DocumentTableActionsDropdownMenu";
import DocumentTableFilterInput from "./DocumentTableFilterInput";
import { useTranslation } from "react-i18next";
import DocumentUploadedByTableCell from "./DocumentUploadedByTableCell";
import DocumentModifiedByTableCell from "./DocumentModifiedByTableCell";
import DocumentTitleTableCell from "./DocumentTitleTableCell";
import DocumentExtensionTableCell from "./DocumentExtensionTableCell";
import DocumentUploadedAtTableCell from "./DocumentUploadedAtTableCell";
import DocumentModifiedAtTableCell from "./DocumentModifiedAtTableCell";

type Props = {
  fragment: DocumentTableFragment;
};

const columnHelper = createColumnHelper<DocumentRowFragment>();

export default function DocumentTable({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();

  const [sorting, setSorting] = useState<SortingState>([
    { id: "lastModifiedAt", desc: true },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data: fragment.memberships,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    enableSortingRemoval: true,
    state: { sorting, columnFilters },
  });

  return (
    <div>
      <div className="flex flex-col md:flex-row py-4 gap-4">
        <div className="mr-auto">
          <DocumentUploadButton />
        </div>
        <DocumentTableFilterInput
          column={table.getColumn("name")!}
          placeholder={t("DocumentTable.filters.title")}
        />
        <DocumentTableFilterInput
          column={table.getColumn("ownerUsername")!}
          placeholder={t("DocumentTable.filters.uploader")}
        />
        <DocumentTableFilterInput
          column={table.getColumn("extension")!}
          placeholder={t("DocumentTable.filters.extension")}
        />
      </div>
      <DocumentTableContent table={table} />
    </div>
  );
}

const columns = [
  columnHelper.accessor("document.name", {
    id: "name",
    header: ({ column }) => <DocumentTitleTableHeader column={column} />,
    cell: (cellContext) => (
      <DocumentTitleTableCell fragment={cellContext.row.original.document} />
    ),
  }),

  columnHelper.accessor("document.uploadedBy.username", {
    id: "ownerUsername",
    header: ({ column }) => <DocumentUploadedByTableHeader column={column} />,
    cell: (cellContext) => (
      <DocumentUploadedByTableCell
        fragment={cellContext.row.original.document}
      />
    ),
  }),

  columnHelper.accessor("document.uploadedAt", {
    header: ({ column }) => <DocumentUploadedAtTableHeader column={column} />,
    cell: (cellContext) => (
      <DocumentUploadedAtTableCell
        fragment={cellContext.row.original.document}
      />
    ),
  }),

  columnHelper.accessor("document.lastModifiedBy.username", {
    header: ({ column }) => <DocumentModifiedByTableHeader column={column} />,
    cell: (cellContext) => (
      <DocumentModifiedByTableCell
        fragment={cellContext.row.original.document}
      />
    ),
  }),

  columnHelper.accessor("document.lastModifiedAt", {
    id: "lastModifiedAt",
    header: ({ column }) => <DocumentModifiedAtTableHeader column={column} />,
    cell: (cellContext) => (
      <DocumentModifiedAtTableCell
        fragment={cellContext.row.original.document}
      />
    ),
  }),

  columnHelper.accessor("document.extension", {
    id: "extension",
    header: ({ column }) => <DocumentExtensionTableHeader column={column} />,
    cell: (cellContext) => (
      <DocumentExtensionTableCell
        fragment={cellContext.row.original.document}
      />
    ),
  }),

  columnHelper.display({
    id: "actions",
    cell: ({ row }) => (
      <DocumentTableActionsDropdownMenu fragment={row.original.document} />
    ),
  }),
];
