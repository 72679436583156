import {
  AccessContextMenuItem,
  AccessContextMenuShortcut,
} from "@acdc2/ui/components/access-context-menu";
import { useTranslation } from "react-i18next";

type Props = {
  disabled?: boolean;
};

export default function RegionCopyContextMenuItem({
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <AccessContextMenuItem disabled={disabled}>
      {t("RegionCopyContextMenuItem.label")}
      <AccessContextMenuShortcut>
        {t("RegionCopyContextMenuItem.shortcut")}
      </AccessContextMenuShortcut>
    </AccessContextMenuItem>
  );
}
