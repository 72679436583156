import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@acdc2/ui/components/accordion";
import { Button } from "@acdc2/ui/components/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@acdc2/ui/components/dialog";
import { Input } from "@acdc2/ui/components/input";
import { Label } from "@acdc2/ui/components/label";
import { useTranslation } from "react-i18next";
import { MetadataKeywordAccordionItemFragment } from "../client/generated";

type Props = {
  fragment: MetadataKeywordAccordionItemFragment;
};

export default function MetadataKeywordAccordionItem({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AccordionItem value="keywords">
      <AccordionTrigger className="mx-2">
        {t("MetadataKeywordAccordionItem.label")}
      </AccordionTrigger>
      {fragment.metadata.subject.map((subject, id) => (
        <AccordionContent className="mx-2" key={id}>
          <Dialog>
            <DialogTrigger asChild>
              <Button variant="ghost" className="w-full justify-start">
                {subject}
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>
                  {t("MetadataKeywordAccordionItem.dialog.title")}
                </DialogTitle>
                <DialogDescription>
                  {t("MetadataKeywordAccordionItem.dialog.description")}
                </DialogDescription>
              </DialogHeader>
              <Label htmlFor="keyword" className="flex flex-col gap-2">
                {t("MetadataKeywordAccordionItem.dialog.keywordInput.label")}
                <Input
                  placeholder={t(
                    "MetadataKeywordAccordionItem.dialog.keywordInput.placeholder",
                  )}
                  defaultValue={subject}
                  id="keyword"
                />
              </Label>
              <DialogFooter>
                <Button variant="destructive">
                  {t("MetadataKeywordAccordionItem.dialog.buttons.delete")}
                </Button>
                <Button type="submit">
                  {t("MetadataKeywordAccordionItem.dialog.buttons.save")}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </AccordionContent>
      ))}
    </AccordionItem>
  );
}
