import { DocumentUploadedAtTableCellFragment } from "../client/generated";
import DocumentDateTableCell from "./DocumentDateTableCell";

type Props = {
  fragment: DocumentUploadedAtTableCellFragment;
};

export default function DocumentUploadedAtTableCell({
  fragment,
}: Props): JSX.Element {
  return <DocumentDateTableCell date={fragment.uploadedAt} />;
}
