import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { TextPreviewFragment } from "../../client/generated";
import { Dispatch } from "react";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";

type Props = {
  fragment: TextPreviewFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function TextPreview({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      tabIdentifier={makeIdentifier(TabPrefix.TextUpdate, fragment.id)}
      label={t("TextPreview.label")}
      preview={fragment.text}
      dispatch={dispatch}
    />
  );
}
