import * as Tabs from "@radix-ui/react-tabs";
import NavbarLogo from "./NavbarLogo";
import ViewerAccountNavbarButton from "./ViewerAccountNavbarButton";
import ViewerLocaleNavbarButton from "./ViewerLocaleNavbarButton";
import ViewerThemeNavbarButton from "./ViewerThemeButton";

type Props = {
  children?: React.ReactNode;
};

export default function Navbar({ children }: Props): JSX.Element {
  return (
    <nav className="w-14 border-r flex flex-col">
      <NavbarLogo />
      <Tabs.List className="flex-1 border-y flex flex-col items-center gap-2 py-2">
        {children}
      </Tabs.List>
      <div className="p-2 flex flex-col items-center gap-2">
        <ViewerLocaleNavbarButton />
        <ViewerThemeNavbarButton />
        <ViewerAccountNavbarButton />
      </div>
    </nav>
  );
}
