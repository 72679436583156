import { useTranslation } from "react-i18next";
import { mdiChevronRight } from "@mdi/js";
import NavbarButton from "../shell/NavbarButton";
import NavbarButtonIcon from "../shell/NavbarButtonIcon";
import { NextPageNavbarButtonFragment } from "../client/generated";
import { routingPaths } from "../navigation/constants";
import { useNavigateTransition } from "../navigation/useNavigateTransition";

type Props = {
  fragment?: NextPageNavbarButtonFragment;
  disabled?: boolean;
};

export default function NextPageNavbarButton({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const [isPending, navigate] = useNavigateTransition();

  const onClick = () => {
    if (fragment && fragment.nextPage) {
      navigate(
        routingPaths.documentPage(fragment.document.id, fragment.nextPage.id),
      );
    }
  };

  return (
    <NavbarButton
      tooltip={t("NextPageNavbarButton.tooltip")}
      loading={isPending}
    >
      <NavbarButtonIcon
        path={mdiChevronRight}
        disabled={!fragment?.nextPage}
        onClick={onClick}
      />
    </NavbarButton>
  );
}
