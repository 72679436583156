import * as Tabs from "@radix-ui/react-tabs";
import { Icon } from "@mdi/react";
import NavbarButton from "./NavbarButton";
import NavbarButtonIcon from "./NavbarButtonIcon";

type Props = {
  value: string;
  tooltip: string;
  iconPath: React.ComponentProps<typeof Icon>["path"];
  disabled?: boolean;
};

export default function NavbarTabButton({
  value,
  tooltip,
  iconPath,
  disabled,
}: Props): JSX.Element {
  return (
    <NavbarButton tooltip={tooltip}>
      <Tabs.Trigger
        value={value}
        className="aria-selected:bg-brand aria-selected:text-brand-foreground"
        asChild
      >
        <NavbarButtonIcon path={iconPath} disabled={disabled} />
      </Tabs.Trigger>
    </NavbarButton>
  );
}
