import { mdiHuman } from "@mdi/js";
import { useTranslation } from "react-i18next";
import NavbarTabButton from "../shell/NavbarTabButton";
import { TabIdentifier } from "./reducers";

type Props = {
  disabled?: boolean;
};

export default function EditorCheckerNavbarTabButton({
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabButton
      tooltip={t("EditorCheckerNavbarTabButton.tooltip")}
      value={TabIdentifier.Checker}
      iconPath={mdiHuman}
      disabled={disabled}
    />
  );
}
