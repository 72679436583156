import { useTranslation } from "react-i18next";
import Scaffold from "../shell/Scaffold";
import Navbar from "../shell/Navbar";

export default function SettingsScreen(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Scaffold navbar={<Navbar />}>
      <h1>{t("SettingsScreen.title")}</h1>
    </Scaffold>
  );
}
