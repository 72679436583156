import { Dispatch, useId, useState } from "react";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import {
  ExpressionUpdateTabFragment,
  useUpdateExpressionAttributeMutation,
} from "../../client/generated";
import { Textarea } from "@acdc2/ui/components/textarea";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import { Label } from "@acdc2/ui/components/label";
import KaTeXPreviewScrollArea from "./KaTeXPreviewScrollArea";
import AttributeDeleteButton from "../AttributeDeleteButton";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";

type Props = {
  fragment: ExpressionUpdateTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function ExpressionUpdateTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [latexExpression, setLatexExpression] = useState<string>(
    fragment.expressionLatex,
  );

  const previewAreaId = useId();
  const textAreaId = useId();

  const [updateExpressionAttribute, { loading }] =
    useUpdateExpressionAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateExpressionAttribute({
      variables: {
        attributeId: fragment.id,
        expressionLatex: latexExpression,
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.ExpressionUpdate, fragment.id)}
    >
      <NavbarTabHeader
        title={t("ExpressionUpdateTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <div className="flex flex-col gap-2">
          <Label htmlFor={previewAreaId}>
            <span>{t("ExpressionUpdateTab.labels.preview")}</span>
          </Label>
          <KaTeXPreviewScrollArea
            latexExpression={latexExpression}
            id={previewAreaId}
          />
        </div>

        <div className="flex flex-col gap-2">
          <Label htmlFor={textAreaId}>
            <span>{t("ExpressionUpdateTab.labels.textarea")}</span>
          </Label>
          <Textarea
            id={textAreaId}
            required
            value={latexExpression}
            onChange={(event) => setLatexExpression(event.target.value)}
            rows={8}
          />
        </div>

        <div className="flex flex-col gap-2">
          <AccessButton type="submit" loading={loading}>
            {t("ExpressionUpdateTab.labels.confirm")}
          </AccessButton>
          <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
        </div>
      </form>
    </NavbarTabContent>
  );
}
