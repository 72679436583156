import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  cx: number;
  cy: number;
  isActive: boolean;
  isOverlapping: boolean;
  onMove: (movementX: number, movementY: number) => void;
  onDone: () => void;
  onSelect: () => void;
};

export default function RegionReshapeHandle({
  cx,
  cy,
  isActive,
  isOverlapping,
  onMove,
  onDone,
  onSelect,
}: Props): JSX.Element {
  const [isDragged, setIsDragged] = useState(false);

  const debouncedDone = useDebouncedCallback(onDone, 1000);

  const onMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDragged(true);
    onSelect();
  };

  useEffect(() => {
    if (!isDragged) return;

    const onMouseMove = (event: MouseEvent) => {
      if (event.buttons !== 1) {
        setIsDragged(false);
        return;
      }

      onMove(event.movementX, event.movementY);
    };

    const onMouseUp = () => {
      setIsDragged(false);
      onDone();
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [isDragged, onMove, onDone]);

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowLeft") {
      onMove(-1, 0);
    } else if (event.key === "ArrowRight") {
      onMove(1, 0);
    } else if (event.key === "ArrowUp") {
      onMove(0, -1);
    } else if (event.key === "ArrowDown") {
      onMove(0, 1);
    }
  };

  const onKeyUp = () => {
    debouncedDone();
  };

  return (
    <>
      <circle
        cx={cx}
        cy={cy}
        r={8}
        fill="hsl(var(--brand))"
        stroke="transparent"
        strokeWidth={8}
        onMouseDown={onMouseDown}
        className="cursor-move"
        vectorEffect="non-scaling-stroke"
        tabIndex={isActive ? 0 : undefined} // Enable receiving keyboard events
        onKeyDown={isActive ? onKeyDown : undefined}
        onKeyUp={isActive ? onKeyUp : undefined}
      />
      <circle
        cx={cx}
        cy={cy}
        r={4}
        fill={isActive || isOverlapping ? "hsl(var(--brand))" : "white"}
        stroke="none"
        className="pointer-events-none"
      />
    </>
  );
}
