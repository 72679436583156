import { DocumentUploadedByTableCellFragment } from "../client/generated";
import UserAvatar from "../users/UserAvatar";

type Props = {
  fragment: DocumentUploadedByTableCellFragment;
};

export default function DocumentUploadedByTableCell({
  fragment,
}: Props): JSX.Element {
  return (
    <div className="flex items-center gap-2">
      <UserAvatar className="h-6 w-6" fragment={fragment.uploadedBy} />
      <span>{fragment.uploadedBy.username}</span>
    </div>
  );
}
