import { cn } from "@ui/lib/utils";
import { DialogOverlay, DialogPortal } from "./dialog";

type Props = {
  className?: string;
};

export function AccessLoading({ className }: Props): JSX.Element {
  return (
    <svg
      className={cn("h-12", className)}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 290.44399 290.44399"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g className="animate-spin origin-center">
        <path
          className="st1"
          d="m 98.247,18.387 c -1.57,-5.49 1.6,-11.2099997 7.1,-12.7799997 5.49,-1.57 11.21,1.62 12.78,7.1099997 1.57,5.48 -1.62,11.2 -7.11,12.76 -0.95,0.28 -1.9,0.4 -2.84,0.4 -4.51,0.01 -8.64,-2.95 -9.93,-7.49 m -30.51,16.02 c -2.91,-4.91 -1.29,-11.26 3.61,-14.16 4.91,-2.91 11.25,-1.29 14.16,3.62 2.91,4.91 1.29,11.25 -3.62,14.16 -1.65,0.98 -3.46,1.45 -5.25,1.45 -3.52,0 -6.97,-1.81 -8.9,-5.07 m -25.48,23.21 c -4.05,-4.01 -4.08,-10.56 -0.07,-14.62 4.02,-4.05 10.56,-4.08 14.62,-0.07 4.05,4.01 4.09,10.56 0.07,14.62 -2.03,2.03 -4.69,3.05 -7.36,3.05 -2.6,0 -5.24,-0.98 -7.26,-2.98"
        />
        <path
          className="st2"
          d="m 26.707,82.027 c -10.06,18.83 -15.77,40.35 -15.77,63.19 0,74.16 60.12,134.29 134.29,134.29 45.57,0 85.83,-22.7 110.11,-57.4"
        />
        <path
          className="st2"
          d="m 259.167,74.107 c -23.73,-37.94 -65.89,-63.17 -113.93,-63.17"
        />
      </g>
      <g className="animate-spin origin-center direction-reverse">
        <path
          className="st2"
          d="m 204.297,76.837 c -15.91,-13.83 -36.69,-22.21 -59.42,-22.21 -50.03,0 -90.59,40.56 -90.59,90.59 0,50.03 40.56,90.59 90.59,90.59 21.01,0 40.35,-7.15 55.72,-19.16"
        />
      </g>
    </svg>
  );
}

export function AccessDialogLoading(): JSX.Element {
  return (
    <DialogPortal>
      <DialogOverlay className="flex justify-center items-center">
        <AccessLoading />
      </DialogOverlay>
    </DialogPortal>
  );
}
