type Props = {
  title: string;
  right?: React.ReactNode;
};

export default function NavbarTabHeader({ title, right }: Props): JSX.Element {
  return (
    <div className="h-14 border-b px-4 py-2 flex flex-row gap-4 items-center justify-between">
      <div className="text-lg">{title}</div>
      <div>{right}</div>
    </div>
  );
}
