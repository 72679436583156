import { useTransition } from "react";
import { NavigateOptions, To, useNavigate } from "react-router";

export function useNavigateTransition() {
  const [isPending, startTransition] = useTransition();
  const navigate = useNavigate();

  const startNavigation = (to: To, options?: NavigateOptions) => {
    startTransition(() => {
      navigate(to, options);
    });
  };

  return [isPending, startNavigation] as const;
}
