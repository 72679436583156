import { mdiWeb } from "@mdi/js";
import { useTranslation } from "react-i18next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@acdc2/ui/components/dropdown-menu";
import NavbarButtonIcon from "./NavbarButtonIcon";
import NavbarButton from "./NavbarButton";

export default function ViewerLocaleNavbarButton(): JSX.Element {
  const { t, i18n } = useTranslation();

  const onValueChange = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language);
  };

  return (
    <DropdownMenu>
      <NavbarButton tooltip={t("ViewerLocaleNavbarButton.title")}>
        <DropdownMenuTrigger asChild>
          <NavbarButtonIcon path={mdiWeb} />
        </DropdownMenuTrigger>
      </NavbarButton>
      <DropdownMenuContent
        className="my-2"
        side="right"
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        <DropdownMenuLabel>
          {t("ViewerLocaleNavbarButton.title")}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup
          value={i18n.language}
          onValueChange={onValueChange}
        >
          <DropdownMenuRadioItem
            value="de"
            children={t("ViewerLocaleNavbarButton.items.de")}
          />
          <DropdownMenuRadioItem
            value="en"
            children={t("ViewerLocaleNavbarButton.items.en")}
          />
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
